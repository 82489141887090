import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'

export const ENTITY_TYPE_DATA_INIT = {
  name: 'DJs',
  displayName: 'DJs',
  displayConditionFilter: ['<ALL>'],
  hideConditionFilter: ['<None>'],
  primaryKeyFieldName: 'djId',
  apiType: 'graphQL',
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: 'https://api<env>.tennis-insider.com/',
  entityLabel: 'Dj',
  additionalInfo: {
    itemIconSvg: <RecordVoiceOverIcon />
  },
  entityLabel1: 'a dj',
  itemCountPerSlice: 500,
  entityInitialState: {
    entityId: 'hb90-Adam-Beyer',
    entityDescription: 'Adam Beyer',
    entitySecondaryText: 'Adam Beyer is a DJ from Sweden'
  },
  graphQLQueryEntitiesAccessPath: ['Entities'],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities:Djs  ( first:500, offset:0
        , orderBy:[orderKey_asc]
        , filter: { djId_not: "XXX" }
      ) {
        entityId:djId
        entityDescription:djName
        entitySecondaryText:biography
        filterKey
        extraInfoTab:countryInfo (first:10, filter: { countryCode_not: "XXX" }) {
          itemName: name
        }
      }                  
  }`
}
