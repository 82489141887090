import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { pink, blueGrey } from "@material-ui/core/colors";

export const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: pink[500],
      light: pink[200],
      dark: pink[700],
    },
    secondary: {
      main: blueGrey[400],
      light: blueGrey[20],
      dark: blueGrey[400],
    },
    type: "dark",
  },
  spacing: 8,
  typography: {
    useNextVariants: true,
  },
});
