import DeviceHubIcon from "@mui/icons-material/DeviceHub";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Odds Ranges",
  displayName: "Odds Ranges",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["Players"],
  primaryKeyFieldName: "oddsRangeId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Odd Range",
  entityLabel1: "an Odd Range",
  additionalInfo: {
    itemIconSvg: <DeviceHubIcon />,
  },
  itemCountPerSlice: 15,
  entityInitialState: {
    entityId: "Odds_0200_0210",
    entityDescription: "[2.0,2.1]",
    entitySecondaryText: "[2.0,2.1]",
    filterKey: '{oddsRangeId:"Odds_0200_0210"}',
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: OddsRanges  ( first:500, offset:0
        , orderBy:[oddsRangeId_asc]
        , filter: { oddsRangeId_not: "XXX" }
      ) {
        entityId:oddsRangeId
        entityDescription:name
        entitySecondaryText:name  
        filterKey       
      } 
  }`,
};
