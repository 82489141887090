import * as filtersActions from '../../01-Actions/filters.actions'
import * as entitiesActions from '../../01-Actions/entities.actions'
import * as UIActions from '../../01-Actions/ui.actions'

export const filtersMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action) //Keeps the loogger well ordered
  var newfilterList = []

  switch (action.type) {
    case filtersActions.EVT_FILTER_ELEM_ADDED:
      newfilterList = action.payload.filterList.slice()
      newfilterList = action.payload.filterList.filter(
        (filterElem) => filterElem.fieldName !== 'searchAll'
      )
      newfilterList.push(action.payload.filterElem)
      dispatch(filtersActions.Fct_DOC_UPDATE_FILTER_LIST(newfilterList))
      dispatch(
        filtersActions.Fct_EVT_FILTER_LIST_UPDATED({
          filterElem: action.payload.filterElem,
          itemCountPerSlice:
            action.payload.filterElem.entityTypeInfo.itemCountPerSlice,
          fullList: action.payload.fullList,
          filterList: newfilterList
        })
      )
      break
    case filtersActions.CMD_UI_ADD_FILTER_ELEM:
      dispatch(UIActions.Fct_DOC_SET_LOADER(true, filtersActions.FILTERS))
      dispatch(filtersActions.Fct_EVT_FILTER_ELEM_ADDED(action.payload))
      setTimeout(() => {
        dispatch(UIActions.Fct_DOC_SET_LOADER(false, filtersActions.FILTERS))
      }, 500)
      break
    case filtersActions.EVT_FILTER_ELEM_REMOVED:
      newfilterList = action.payload.filterList.filter(
        (filterElem) => filterElem.id !== action.payload.filterElem.id
      )
      dispatch(filtersActions.Fct_DOC_UPDATE_FILTER_LIST(newfilterList))
      dispatch(
        filtersActions.Fct_EVT_FILTER_LIST_UPDATED({
          filterElem: action.payload.filterElem,
          itemCountPerSlice:
            action.payload.filterElem.entityTypeInfo.itemCountPerSlice,
          fullList: action.payload.fullList,
          filterList: newfilterList
        })
      )
      break
    case filtersActions.CMD_UI_REMOVE_FILTER_ELEM:
      dispatch(UIActions.Fct_DOC_SET_LOADER(true, filtersActions.FILTERS))
      dispatch(filtersActions.Fct_EVT_FILTER_ELEM_REMOVED(action.payload))
      setTimeout(() => {
        dispatch(UIActions.Fct_DOC_SET_LOADER(false, filtersActions.FILTERS))
      }, 500)
      break
    case filtersActions.EVT_FILTER_LIST_UPDATED:
      console.log(action.payload)
      dispatch(
        entitiesActions.Fct_EVT_ENTITIES_LIST_FILTERED({
          entityTypeInfo: action.payload.filterElem.entityTypeInfo,
          itemCountPerSlice:
            action.payload.filterElem.entityTypeInfo.itemCountPerSlice,
          fullList: action.payload.fullList,
          filterList: action.payload.filterList
        })
      )
      break
    case entitiesActions.CMD_APP_RESET_ENTITIES:
      dispatch(
        filtersActions.Fct_DOC_RESET_FILTERS(action.payload.entityTypeInfo)
      )
      break
    default:
  }
}
