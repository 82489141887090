export const cleanDisplayName = (inputString) => {
  inputString = inputString.replace(/_x_c3_x_89/g, 'É')
  inputString = inputString.replace(/_x_c4_x_8c/g, 'Č')
  inputString = inputString.replace(/_x_c2_x_a1/g, '¡')
  inputString = inputString.replace(/_x_c2_x_a2/g, '¢')
  inputString = inputString.replace(/_x_c2_x_a3/g, '£')
  inputString = inputString.replace(/_x_c2_x_a4/g, '¤')
  inputString = inputString.replace(/_x_c2_x_a5/g, '¥')
  inputString = inputString.replace(/_x_c2_x_a6/g, '¦')
  inputString = inputString.replace(/_x_c2_x_a7/g, '§')
  inputString = inputString.replace(/_x_c2_x_a8/g, '¨')
  inputString = inputString.replace(/_x_c2_x_a9/g, '©')
  inputString = inputString.replace(/_x_c2_x_aa/g, 'ª')
  inputString = inputString.replace(/_x_c2_x_ab/g, '«')
  inputString = inputString.replace(/_x_c2_x_ac/g, '¬')
  inputString = inputString.replace(/_x_c2_x_ad/g, '­')
  inputString = inputString.replace(/_x_c2_x_ae/g, '®')
  inputString = inputString.replace(/_x_c2_x_af/g, '¯')
  inputString = inputString.replace(/_x_c2_x_b0/g, '°')
  inputString = inputString.replace(/_x_c2_x_b1/g, '±')
  inputString = inputString.replace(/_x_c2_x_b2/g, '²')
  inputString = inputString.replace(/_x_c2_x_b3/g, '³')
  inputString = inputString.replace(/_x_c2_x_b4/g, '´')
  inputString = inputString.replace(/_x_c2_x_b5/g, 'µ')
  inputString = inputString.replace(/_x_c2_x_b6/g, '¶')
  inputString = inputString.replace(/_x_c2_x_b7/g, '·')
  inputString = inputString.replace(/_x_c2_x_b8/g, '¸')
  inputString = inputString.replace(/_x_c2_x_b9/g, '¹')
  inputString = inputString.replace(/_x_c2_x_ba/g, 'º')
  inputString = inputString.replace(/_x_c2_x_bb/g, '»')
  inputString = inputString.replace(/_x_c2_x_bc/g, '¼')
  inputString = inputString.replace(/_x_c2_x_bd/g, '½')
  inputString = inputString.replace(/_x_c2_x_be/g, '¾')
  inputString = inputString.replace(/_x_c2_x_bf/g, '¿')
  inputString = inputString.replace(/_x_c3_x_80/g, 'À')
  inputString = inputString.replace(/_x_c3_x_81/g, 'Á')
  inputString = inputString.replace(/_x_c3_x_82/g, 'Â')
  inputString = inputString.replace(/_x_c3_x_83/g, 'Ã')
  inputString = inputString.replace(/_x_c3_x_84/g, 'Ä')
  inputString = inputString.replace(/_x_c3_x_85/g, 'Å')
  inputString = inputString.replace(/_x_c3_x_86/g, 'Æ')
  inputString = inputString.replace(/_x_c3_x_87/g, 'Ç')
  inputString = inputString.replace(/_x_c3_x_88/g, 'È')
  inputString = inputString.replace(/_x_c3_x_89/g, 'É')
  inputString = inputString.replace(/_x_c3_x_8a/g, 'Ê')
  inputString = inputString.replace(/_x_c3_x_8b/g, 'Ë')
  inputString = inputString.replace(/_x_c3_x_8c/g, 'Ì')
  inputString = inputString.replace(/_x_c3_x_8d/g, 'Í')
  inputString = inputString.replace(/_x_c3_x_8e/g, 'Î')
  inputString = inputString.replace(/_x_c3_x_8f/g, 'Ï')
  inputString = inputString.replace(/_x_c3_x_90/g, 'Ð')
  inputString = inputString.replace(/_x_c3_x_91/g, 'Ñ')
  inputString = inputString.replace(/_x_c3_x_92/g, 'Ò')
  inputString = inputString.replace(/_x_c3_x_93/g, 'Ó')
  inputString = inputString.replace(/_x_c3_x_94/g, 'Ô')
  inputString = inputString.replace(/_x_c3_x_95/g, 'Õ')
  inputString = inputString.replace(/_x_c3_x_96/g, 'Ö')
  inputString = inputString.replace(/_x_c3_x_97/g, '×')
  inputString = inputString.replace(/_x_c3_x_98/g, 'Ø')
  inputString = inputString.replace(/_x_c3_x_99/g, 'Ù')
  inputString = inputString.replace(/_x_c3_x_9a/g, 'Ú')
  inputString = inputString.replace(/_x_c3_x_9b/g, 'Û')
  inputString = inputString.replace(/_x_c3_x_9c/g, 'Ü')
  inputString = inputString.replace(/_x_c3_x_9d/g, 'Ý')
  inputString = inputString.replace(/_x_c3_x_9e/g, 'Þ')
  inputString = inputString.replace(/_x_c3_x_9f/g, 'ß')
  inputString = inputString.replace(/_x_c3_x_a0/g, 'à')
  inputString = inputString.replace(/_x_c3_x_a1/g, 'á')
  inputString = inputString.replace(/_x_c3_x_a2/g, 'â')
  inputString = inputString.replace(/_x_c3_x_a3/g, 'ã')
  inputString = inputString.replace(/_x_c3_x_a4/g, 'ä')
  inputString = inputString.replace(/_x_c3_x_a5/g, 'å')
  inputString = inputString.replace(/_x_c3_x_a6/g, 'æ')
  inputString = inputString.replace(/_x_c3_x_a7/g, 'ç')
  inputString = inputString.replace(/_x_c3_x_a8/g, 'è')
  inputString = inputString.replace(/_x_c3_x_a9/g, 'é')
  inputString = inputString.replace(/_x_c3_x_aa/g, 'ê')
  inputString = inputString.replace(/_x_c3_x_ab/g, 'ë')
  inputString = inputString.replace(/_x_c3_x_ac/g, 'ì')
  inputString = inputString.replace(/_x_c3_x_ad/g, 'í')
  inputString = inputString.replace(/_x_c3_x_ae/g, 'î')
  inputString = inputString.replace(/_x_c3_x_af/g, 'ï')
  inputString = inputString.replace(/_x_c3_x_b0/g, 'ð')
  inputString = inputString.replace(/_x_c3_x_b1/g, 'ñ')
  inputString = inputString.replace(/_x_c3_x_b2/g, 'ò')
  inputString = inputString.replace(/_x_c3_x_b3/g, 'ó')
  inputString = inputString.replace(/_x_c3_x_b4/g, 'ô')
  inputString = inputString.replace(/_x_c3_x_b5/g, 'õ')
  inputString = inputString.replace(/_x_c3_x_b6/g, 'ö')
  inputString = inputString.replace(/_x_c3_x_b7/g, '÷')
  inputString = inputString.replace(/_x_c3_x_b8/g, 'ø')
  inputString = inputString.replace(/_x_c3_x_b9/g, 'ù')
  inputString = inputString.replace(/_x_c3_x_ba/g, 'ú')
  inputString = inputString.replace(/_x_c3_x_bb/g, 'û')
  inputString = inputString.replace(/_x_c3_x_bc/g, 'ü')
  inputString = inputString.replace(/_x_c3_x_bd/g, 'ý')
  inputString = inputString.replace(/_x_c3_x_be/g, 'þ')
  inputString = inputString.replace(/_x_c3_x_bf/g, 'ÿ')

  inputString = inputString.replace(/_x_c8_x_a1/g, 'ȡ')
  inputString = inputString.replace(/_x_e2_x_b1/g, 'ⱹ')
  inputString = inputString.replace(/_x_b9_x_e2/g, 'ⱸ')
  inputString = inputString.replace(/_x_b1_x_b8/g, 'ą')
  inputString = inputString.replace(/_x_c4_x_85/g, 'Ṃ')
  inputString = inputString.replace(/_x_e1_x_b9/g, 'ɐ')
  inputString = inputString.replace(/_x_82_x_c9/g, 'ƈ')
  inputString = inputString.replace(/_x_95_x_e1/g, 'ƕ')
  inputString = inputString.replace(/_x_b8_x_af/g, 'ḯ')
  inputString = inputString.replace(/_x_c9_x_b2/g, 'ɲ')
  inputString = inputString.replace(/_x_c9_x_9b/g, 'ɛ')

  return inputString
}
