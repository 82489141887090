import React, { useState } from 'react'
import { DnaSpinner } from 'tennisinsiderwebapp'
import pink from '@material-ui/core/colors/pink'
import cyan from '@material-ui/core/colors/cyan'
import EntitiesList from '../components/EntitiesList'
import { useSelector, useDispatch } from 'react-redux'
import { mystyles } from '../../mystyles-css'
import withWidth from '@material-ui/core/withWidth'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import * as UIActions from '../../actionManagement/01-Actions/ui.actions'
import * as Actions from '../../actionManagement/01-Actions/entityTypes.actions'
import PopOverMenu from '../components/PopOverMenu'

function HomePage(props) {
  const state = useSelector((state) => state)

  const entityTypesList = state.entityTypes.entityTypesList
  const yearsList = state.entityTypes.yearsList

  const ENTITY_TYPE = state.entityTypes.entityTypeSelName
  const ENTITY_TYPE_INFO = entityTypesList.filter(
    (elem) => elem.name === ENTITY_TYPE
  )[0]
  const {
    mainListSliceIndexPrev,
    mainListSliceIndex,
    anchorElInit,
    entitiesSelectedYear
  } = state.ui

  const { filterList } = state.filters

  const isMenuOpen = Boolean(anchorElInit)

  const loading = state.ui.loading.data
  const firstEntitiesDisplayCount = state.entityTypes.firstEntitiesDisplayCount

  const entityTypesListFull = state.entityTypes.entityTypesList.filter(
    (elem) => elem.isVisible
  )
  const entityTypesListTabs = entityTypesListFull.slice(
    0,
    firstEntitiesDisplayCount
  )

  const APP_ENTITIES = state.entityTypes.entityTypeSelName
  const mainListEntityCount = state.entities.entityCount
  const filteredListCount = state.entities.dataObjects.filteredList.entityCount

  var env = ''

  if (process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production') {
    env =
      '{env:' +
      process.env.REACT_APP_CUSTOM_NODE_ENV +
      ',version:' +
      process.env.REACT_APP_TIW_VERSION +
      '}'
  }

  const titleText =
    mainListEntityCount === filteredListCount
      ? env + ' Welcome! Listing ' + filteredListCount + ' ' + APP_ENTITIES
      : env +
        ' Welcome! Displaying ' +
        filteredListCount +
        ' / ' +
        mainListEntityCount +
        ' ' +
        APP_ENTITIES

  const dispatch = useDispatch()

  var entityTypeSelIndex = entityTypesListTabs.findIndex(function (entityType) {
    return entityType.name === state.entityTypes.entityTypeSelName
  })

  const [value, setValue] = useState(entityTypeSelIndex)

  const [selectedYear, setSelectedYear] = useState(entitiesSelectedYear)

  // const entityTypesListExtra = entityTypesListFull.slice(
  //   firstEntitiesDisplayCount,
  //   entityTypesListFull.length
  // )

  // const handleChangeExtra = (event) => {
  //   const entityTypeSelName = event.target.childNodes[0].data
  //   setValue(firstEntitiesDisplayCount)
  //   dispatch(
  //     Actions.Fct_CMD_UI_SET_ENTITY_TYPE_SEL({
  //       entityTypeSelName,
  //       filterList,
  //       eventTrigger: 'UI.HomePage.PopOverMenu.handleChangeExtra'
  //     })
  //   )
  // }

  const handleSearchButtonClick = () => {
    dispatch(
      UIActions.Fct_CMD_GO_TO_SEARCH_PAGE({
        mainListSliceIndex: mainListSliceIndex,
        entityTypeInfo: ENTITY_TYPE_INFO,
        eventTrigger: 'HomePage.SearchButton.handleSearchButtonClick',
        fullList: state.entities.dataObjects.fullList,
        filteredList: state.entities.dataObjects.filteredList,
        entitiesSelectedYear
      })
    )
    console.log('go to search page')
  }

  const handleChange = (event, index) => {
    const entityIndex = index
    const entityTypeSelName = event.target.childNodes[0].data
    if (entityIndex >= 0 && entityIndex < firstEntitiesDisplayCount) {
      setValue(index)
      dispatch(
        Actions.Fct_CMD_UI_SET_ENTITY_TYPE_SEL({
          entityTypeSelName: entityTypeSelName,
          filterList,
          mainListSliceIndex: mainListSliceIndexPrev,
          entityTypeInfo: ENTITY_TYPE_INFO,
          fullList: state.entities.dataObjects.fullList,
          filteredList: state.entities.dataObjects.filteredList,
          entitiesSelectedYear,
          eventTrigger: 'UI.HomePage.Tab.handleChange'
        })
      )
    }
  }

  const handleChangeYear = (event) => {
    const newSelectedYear = event.target.childNodes[0].data
    setSelectedYear(newSelectedYear)
    dispatch(
      UIActions.Fct_CMD_SET_YEAR_SEL({
        entitiesSelectedYear: newSelectedYear,
        mainListSliceIndex: mainListSliceIndex,
        entityTypeInfo: ENTITY_TYPE_INFO,
        fullList: state.entities.dataObjects.fullList,
        filteredList: state.entities.dataObjects.filteredList,
        entityContextInfo: {
          filterList: filterList,
          entitiesSelectedYear: newSelectedYear,
          uiTrigger: 'UI.HomePage.SelectYearMenu.handleChangeYear'
        },
        eventTrigger: 'UI.HomePage.SelectYearMenu.handleChangeYear'
      })
    )
  }

  // const handleChangeExtra = (event) => {
  //   const entityTypeSelName = event.target.childNodes[0].data;
  //   setValue(firstEntitiesDisplayCount);
  //   dispatch(
  //     Actions.Fct_CMD_UI_SET_ENTITY_SEL({
  //       entityTypeSelName,
  //       filterList,s
  //       eventTrigger: "UI.HomePage.PopOverMenu.handleChangeExtra",
  //     })
  //   );
  // };

  return [
    <AppBar key='AppBAr' position='static'>
      <Toolbar>
        <Tabs
          key='tabs'
          value={value}
          onChange={handleChange}
          aria-label='Entities Navigator'
        >
          {entityTypesListTabs.map((elem) => [
            <Tab label={elem.displayName} key={elem.id} id={elem.id} />
          ])}
        </Tabs>

        <div key='divYear' style={mystyles.DivYear}>
          <PopOverMenu
            menuList={yearsList}
            handleChange={handleChangeYear}
            selectedYear={selectedYear}
          />
        </div>
        <div>
          <IconButton
            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
            aria-haspopup='true'
            color='inherit'
          >
            <CalendarTodayIcon />
          </IconButton>
        </div>
        <div key='divExtraButtons'>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='go to search page'
            sx={{ mr: 2 }}
            onClick={handleSearchButtonClick}
          >
            <SearchIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>,

    <div key='divRoot' style={mystyles.DivRoot}>
      <Grid container spacing={2}>
        <Grid key='g62' item xl={1} xs={1}></Grid>
        <Grid key='g1' item xl={5} xs={10}>
          <Paper style={mystyles.PaperGrid}>{titleText}</Paper>
        </Grid>
        <Grid key='g6' item xl={6} xs={1}></Grid>
        <Grid key='g61' item xl={1} xs={1}></Grid>
        <Grid key='g7' item xl={5} xs={10}>
          <Paper style={mystyles.PaperGrid}>
            {!loading ? <EntitiesList /> : null}
            {loading ? (
              <DnaSpinner
                titleId='DnaSpinner'
                title='DnaSpinner'
                backgroundColor='#282c34'
                colorsWave31={pink[200]}
                colorsWave32={pink[400]}
                colorsWave41={cyan[200]}
                colorsWave42={cyan[400]}
              />
            ) : null}
          </Paper>
        </Grid>
        <Grid key='g8' item xl={6} xs={1}></Grid>
      </Grid>
    </div>
  ]
}

export default withWidth()(HomePage)
