import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Surfaces",
  displayName: "Surfaces",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "surfaceId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Surface",
  entityLabel1: "a surface",
  additionalInfo: {
    itemIconSvg: <CalendarViewDayIcon />,
  },
  itemCountPerSlice: 5,
  entityInitialState: {
    surfaceId: "hard",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: Surfaces  ( first:500, offset:0
        , orderBy:[surfaceId_desc]
        , filter: { surfaceId_not: "XXX" }
      ) {
        entityId:surfaceId
        entityDescription:longName
        entitySecondaryText:name  
        filterKey       
      } 
  }`,
};
