export const INFO_TYPE = "api";
export const CMD_CORE_API_REQUEST = `[${INFO_TYPE}] CMD_CORE_API_REQUEST`;
export const CMD_CORE_GRAPHQL_REQUEST = `[${INFO_TYPE}] CMD_CORE_GRAPHQL_REQUEST`;
export const EVT_API_SUCCESS = `[${INFO_TYPE}] EVT_API_SUCCESS`;
export const EVT_API_ERROR = `[${INFO_TYPE}] EVT_API_ERROR`;

export const CMD_CORE_ApiRequest = (
  body,
  method,
  entityTypeInfo,
  entityContextInfo
) => ({
  type: CMD_CORE_API_REQUEST,
  payload: {
    data: body,
    meta: { method, entityTypeInfo, entityContextInfo },
  },
});

export const CMD_CORE_GraphQLRequest = (
  body,
  method,
  entityTypeInfo,
  entityContextInfo
) => ({
  type: CMD_CORE_GRAPHQL_REQUEST,
  payload: {
    data: body,
    meta: { method, entityTypeInfo, entityContextInfo },
  },
});

export const Evt_ApiSuccess = (
  response,
  entityTypeInfo,
  entityContextInfo
) => ({
  type: EVT_API_SUCCESS,
  payload: {
    data: response,
    entityTypeInfo,
    entityContextInfo,
  },
});

export const Evt_ApiError = (error, entityTypeInfo, entityContextInfo) => ({
  type: EVT_API_ERROR,
  payload: {
    data: error,
    entityTypeInfo,
    entityContextInfo,
  },
});
