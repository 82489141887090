import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Categories",
  displayName: "Categories",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "tournamentCategoryId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Categories",
  entityLabel1: "a tournament category",
  additionalInfo: {
    itemIconSvg: <CalendarViewDayIcon />,
  },
  itemCountPerSlice: 10,
  entityInitialState: {
    tournamentCategoryId: "04-ATP250",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: TournamentCategories ( first:500, offset:0
        , orderBy:[tournamentCategoryId_asc]
        , filter: { tournamentCategoryId_not: "XXX" }
      ) {
        entityId:tournamentCategoryId
        entityDescription:longName
        entitySecondaryText:name  
        filterKey       
      } 
  }`,
};
