import * as APIActions from '../../01-Actions/api.actions'
import * as entitiesActions from '../../01-Actions/entities.actions'
import * as entityTypesActions from '../../01-Actions/entityTypes.actions'
import * as filtersTransformations from '../../02b-Transformations/filters.transformations'
import * as notificationsActions from '../../01-Actions/notifications.actions'
import * as UIActions from '../../01-Actions/ui.actions'

const setNewEntitiesList = (payload) => {
  var entitiesList = []
  var entity = {}
  const { entityTypeInfo, data } = payload

  var entitiesListSource = []
  if (entityTypeInfo.graphQLQueryEntitiesAccessPath.length === 1) {
    entitiesListSource = data.Entities
  } else if (entityTypeInfo.graphQLQueryEntitiesAccessPath.length === 2) {
    entitiesListSource = data.Entities[0].EntitiesLevel2
  } else if (entityTypeInfo.graphQLQueryEntitiesAccessPath.length === 3) {
    entitiesListSource = data.Entities[0].EntitiesLevel2[0].EntitiesLevel3
  } else if (entityTypeInfo.graphQLQueryEntitiesAccessPath.length === 4) {
    entitiesListSource =
      data.Entities[0].EntitiesLevel2[0].EntitiesLevel3[0].EntitiesLevel4
  }

  for (let k = 0; k < entitiesListSource.length; k++) {
    const elem = entitiesListSource[k]
    entity = {
      id: elem.entityId,
      showBin: entityTypeInfo.showBin,
      description: elem.entityDescription,
      extraInfoTab: elem.extraInfoTab,
      additionalInfo: entityTypeInfo.additionalInfo,
      secondaryText: elem.entitySecondaryText,
      filterKey: elem.filterKey
    }
    if (entity !== undefined) {
      entitiesList.push(entity)
    }
  }
  return entitiesList
}

export const entitiesMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action)
  var newFilteredList = {}

  switch (action.type) {
    case entitiesActions.CMD_UI_ADD_ENTITY:
      dispatch(
        UIActions.Fct_DOC_SET_LOADER(true, action.payload.entityTypeInfo)
      )
      dispatch(
        entitiesActions.Fct_DOC_CREATE_ENTITY(
          action.payload,
          action.payload.entityTypeInfo
        )
      )
      dispatch(
        entitiesActions.Fct_EVT_ENTITY_CREATED(
          action.payload,
          action.payload.entityTypeInfo
        )
      )
      setTimeout(() => {
        dispatch(
          UIActions.Fct_DOC_SET_LOADER(false, action.payload.entityTypeInfo)
        )
      }, 300)
      break
    case entitiesActions.CMD_UI_REMOVE_ENTITY:
      dispatch(
        UIActions.Fct_DOC_SET_LOADER(true, action.payload.entityTypeInfo)
      )
      dispatch(
        entitiesActions.Fct_DOC_REMOVE_ENTITY(
          action.payload.id,
          action.payload.entityTypeInfo
        )
      )
      dispatch(
        entitiesActions.Fct_EVT_ENTITY_REMOVED(
          action.payload,
          action.payload.entityTypeInfo
        )
      )
      setTimeout(() => {
        dispatch(
          UIActions.Fct_DOC_SET_LOADER(false, action.payload.entityTypeInfo)
        )
      }, 300)
      break
    case entitiesActions.CMD_APP_RESET_ENTITIES:
      dispatch(
        entitiesActions.Fct_DOC_CLEAR_ENTITIES(action.payload.entityTypeInfo)
      )
      break
    case entityTypesActions.EVT_ENTITY_TYPE_CHANGED:
    case entitiesActions.CMD_GET_ENTITIES_FROM_CLOUD:
      dispatch(
        entitiesActions.Fct_DOC_CLEAR_ENTITIES(action.payload.entityTypeInfo)
      )
      if (action.payload.entityTypeInfo.apiType === 'standardAPI') {
        dispatch(
          APIActions.CMD_CORE_ApiRequest(
            null,
            'GET',
            action.payload.entityTypeInfo,
            action.payload.entityContextInfo
          )
        )
      } else if (action.payload.entityTypeInfo.apiType === 'graphQL') {
        dispatch(
          APIActions.CMD_CORE_GraphQLRequest(
            null,
            'GET',
            action.payload.entityTypeInfo,
            action.payload.entityContextInfo
          )
        )
      }
      break
    case entitiesActions.CMD_UI_REMOVE_ALL_ENTITIES:
      dispatch(
        UIActions.Fct_DOC_SET_LOADER(true, action.payload.entityTypeInfo)
      )
      dispatch(
        entitiesActions.Fct_CMD_APP_RESET_ENTITIES(
          action.payload.entityTypeInfo
        )
      )
      setTimeout(() => {
        dispatch(
          UIActions.Fct_DOC_SET_LOADER(false, action.payload.entityTypeInfo)
        )
      }, 1000)
      break
    case entitiesActions.EVT_ENTITIES_LIST_UPDATED:
      newFilteredList = filtersTransformations.setNewFilteredEntitiesList({
        itemCountPerSlice: action.payload.entityTypeInfo.itemCountPerSlice,
        fullList: action.payload.fullList,
        filterList: action.payload.filterList
      })
      dispatch(entitiesActions.DOC_FilterEntitiesList(newFilteredList))
      dispatch(
        entitiesActions.EVT_EntitiesListFiltered({
          entityTypeInfo: action.payload.entityTypeInfo,
          filterList: action.payload.filterList,
          mainListSliceIndex: 1,
          itemCountPerSlice: action.payload.entityTypeInfo.itemCountPerSlice,
          filteredList: newFilteredList
        })
      )
      break
    case entitiesActions.EVT_ENTITIES_LIST_FILTERED:
      dispatch(
        entitiesActions.Fct_DOC_SLICE_ENTITIES_LIST({
          mainListSliceIndex: action.payload.mainListSliceIndex,
          itemCountPerSlice: action.payload.itemCountPerSlice,
          filteredList: action.payload.filteredList,
          filterList: action.payload.filterList
        })
      )
      dispatch(
        entitiesActions.Fct_EVT_ENTITIES_LIST_SLICED({
          mainListSliceIndex: action.payload.mainListSliceIndex,
          itemCountPerSlice: action.payload.itemCountPerSlice,
          filteredList: action.payload.filteredList,
          filterList: action.payload.filterList
        })
      )
      break
    case APIActions.EVT_API_SUCCESS:
      const fullList = setNewEntitiesList(action.payload)
      dispatch(entitiesActions.Fct_DOC_UPDATE_ENTITIES_LIST(fullList))
      dispatch(
        entitiesActions.Fct_EVT_ENTITIES_LIST_FILTERED({
          fullList,
          entityTypeInfo: action.payload.entityTypeInfo,
          filterList: action.payload.entityContextInfo.filterList
        })
      )
      break
    case APIActions.EVT_API_ERROR:
      dispatch(
        notificationsActions.Fct_CREATE_NOTIFICATION(
          action.payload.data.message,
          action.payload.entityTypeInfo
        )
      )
      break
    default:
  }
}
