import DeviceHubIcon from "@mui/icons-material/DeviceHub";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Player Rounds",
  displayName: "Player Rounds",
  displayConditionFilter: ["Players"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "roundId",
  apiType: "graphQL",
  isVisible: false,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Round",
  entityLabel1: "a round",
  additionalInfo: {
    itemIconSvg: <DeviceHubIcon />,
  },
  itemCountPerSlice: 50,
  entityInitialState: {
    entityId: "01-F",
    entityDescription: "Final",
    entitySecondaryText: "Final",
    filterKey: '{roundId:"01-F"}',
  },
  graphQLQueryEntitiesAccessPath: ["Entities", "EntitiesLevel2"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: PlayerSeasons(
      first: 1
      offset: 0
      orderBy: [birthday_desc]
      filter: { playerSeasonId_not: "1900-01-01-unmatched-player" }
      playerSeasonId:  "<playerSeasonId>"
    ) {
      entityId: playerSeasonId
      entityDescription: name
      entitySecondaryText: shortDesc
      filterKey
      EntitiesLevel2:  rounds {
        entityId:roundId
        entityDescription:longName
        entitySecondaryText:longName
        filterKey
      }
    }
  }`,
};
