import * as Actions from "../01-Actions/filters.actions";

const initialState = { filterList: [] };

const filtersReducer = (state = initialState, action) => {
  var newState = state;

  switch (action.type) {
    case Actions.DOC_UPDATE_FILTER_LIST:
      let filterList = action.payload.slice();
      newState.filterList = filterList;
      break;
    case Actions.DOC_RESET_FILTERS:
      newState.filterList = [];
      break;
    default:
      break;
  }
  return newState;
};

export default filtersReducer;
