import * as UiActions from "../../01-Actions/ui.actions";
import * as entitiesActions from "../../01-Actions/entities.actions";

export const uiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action); //Keeps the loogger well ordered
  switch (action.type) {
    case UiActions.CMD_GO_TO_MAIN_LIST_SLICE:
      dispatch(
        UiActions.Fct_DOC_SET_MAIN_LIST_SLICE_INDEX(
          action.payload.mainListSliceIndex
        )
      );
      dispatch(UiActions.Fct_EVT_MAIN_LIST_SLICE_INDEX_CHANGED(action.payload));
      break;
    case UiActions.CMD_GO_TO_HOME_PAGE:
      dispatch(UiActions.Fct_EVT_USER_WENT_TO_HOME_PAGE(action.payload));
      break;
    case UiActions.CMD_SET_YEAR_SEL:
      dispatch(UiActions.Fct_DOC_SET_YEAR_SEL(action.payload));
      dispatch(UiActions.Fct_EVT_YEAR_SEL_CHANGED(action.payload));
      break;
    case UiActions.CMD_GO_TO_SEARCH_PAGE:
      dispatch(UiActions.Fct_EVT_USER_WENT_TO_SEARCH_PAGE(action.payload));
      break;
    case UiActions.CMD_CHANGE_SEARCH_TEXT:
      dispatch(UiActions.Fct_EVT_SEARCHED_TEXT_CHANGED(action.payload));
      break;
    case UiActions.EVT_SEARCHED_TEXT_CHANGED:
      break;
    case UiActions.EVT_YEAR_SEL_CHANGED:
      console.log(action.payload);
      dispatch(entitiesActions.Fct_CMD_GET_ENTITIES_FROM_CLOUD(action.payload));
      // dispatch(
      //   entitiesActions.Fct_CMD_GET_ENTITIES_FROM_CLOUD({
      //     entityTypeInfo: ENTITY_TYPE_INFO,
      //     entityContextInfo: {
      //       filterList: FILTER_LIST,
      //       entitiesSelectedYear: SELECTED_YEAR,
      //       uiTrigger:
      //         'HomePage.YearSelMenu.handleChange'
      //     }
      //   })
      // )

      break;
    case UiActions.EVT_MAIN_LIST_SLICE_INDEX_CHANGED:
      dispatch(entitiesActions.Fct_DOC_SLICE_ENTITIES_LIST(action.payload));
      break;
    case entitiesActions.EVT_ENTITIES_LIST_FILTERED:
      dispatch(UiActions.Fct_DOC_SET_MAIN_LIST_SLICE_COUNT(action.payload));
      break;
    default:
  }
};
