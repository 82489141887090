import * as Actions from '../01-Actions/ui.actions'

const initState = {
  loading: false,
  mainListEntityCount: 0,
  mainListSliceIndex: 1,
  mainListSliceIndexNext: 1,
  mainListSliceIndexPrev: 1,
  mainListSliceCount: 0,
  entitiesSelectedYear: '' + new Date().getFullYear(),
  anchorElInit: null
}

export const uiReducer = (state = initState, action) => {
  const { payload } = action
  var mainListSliceIndex = 1
  var mainListSliceIndexNext = 1
  var mainListSliceIndexPrev = 1

  switch (action.type) {
    case Actions.DOC_SET_LOADER:
      return { ...state, loading: payload }
    case Actions.DOC_SET_MAIN_LIST_SLICE_INDEX:
      mainListSliceIndex = payload
      mainListSliceIndexNext =
        mainListSliceIndex + 1 > state.mainListSliceCount
          ? state.mainListSliceCount
          : mainListSliceIndex + 1
      mainListSliceIndexPrev =
        mainListSliceIndex - 1 <= 0 ? 1 : mainListSliceIndex - 1
      return {
        ...state,
        mainListSliceIndex: mainListSliceIndex,
        mainListSliceIndexNext: mainListSliceIndexNext,
        mainListSliceIndexPrev: mainListSliceIndexPrev
      }
    case Actions.DOC_SET_MAIN_LIST_ENTITY_COUNT:
      const mainListEntityCount = payload
      return {
        ...state,
        mainListEntityCount: mainListEntityCount
      }
    case Actions.DOC_SET_YEAR_SEL:
      const entitiesSelectedYear = payload.entitiesSelectedYear
      return {
        ...state,
        entitiesSelectedYear: entitiesSelectedYear
      }
    case Actions.DOC_SET_MAIN_LIST_SLICE_COUNT:
      const mainListSliceCount = payload.filteredList.sliceCount
      mainListSliceIndexNext =
        mainListSliceIndex + 1 >= mainListSliceCount
          ? mainListSliceCount
          : mainListSliceIndex + 1
      return {
        ...state,
        mainListSliceCount: mainListSliceCount,
        mainListSliceIndexNext: mainListSliceIndexNext
      }
    default:
      return state
  }
}

export default uiReducer
