import WhatshotIcon from "@mui/icons-material/Whatshot";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Matches",
  displayName: "Matches",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: [
    "Categories",
    "Countries",
    "Odds Ranges",
    "Players",
    "Rounds",
    "Surfaces",
    "Tournaments",
  ],
  primaryKeyFieldName: "matchId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Match",
  additionalInfo: {
    itemIconSvg: <WhatshotIcon />,
  },
  entityLabel1: "a match",
  itemCountPerSlice: 50,
  entityInitialState: {
    entityId:
      "2021_05_22_1998_12_22_Casper_Ruud_1999_04_15_Denis_Shapovalov_Ruud_C__vs_Shapovalov_D_",
    entityDescription: "2021-05-22 GENEV F Casper Ruud won vs Denis Shapovalov",
    entitySecondaryText: " 7-6 6-4",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities:Matches  ( first:500, offset:0
        , orderBy:[matchDate_desc,tournamentSeasonId_asc,matchRound_asc]
        , filter: { tournamentSeasonId_not: "XXX"
      }
      ) {
        entityId:matchId
        entityDescription:shortDesc5
        entitySecondaryText:shortDesc4
        filterKey
      }                  
  }`,
};
