import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Player Groups",
  displayName: "Player Groups",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "playerGroupId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Player Group",
  entityLabel1: "a Player Group",
  additionalInfo: {
    itemIconSvg: <SupervisedUserCircleIcon />,
  },
  itemCountPerSlice: 5,
  entityInitialState: {
    PlayerGroupId: "hard",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: PlayerGroups  ( first:500, offset:0
        , orderBy:[playerGroupId_asc]
        , filter: { playerGroupId_not: "XXX" }
      ) {
        entityId:playerGroupId
        entityDescription:description
        entitySecondaryText:name  
        filterKey       
      } 
  }`,
};
