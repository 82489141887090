export const FILTERS = 'filters'
export const DOC_UPDATE_FILTER_LIST = `[${FILTERS}] DOC_UPDATE_FILTER_LIST`
export const DOC_RESET_FILTERS = `[${FILTERS}] DOC_RESET_FILTER`
export const CMD_UI_ADD_FILTER_ELEM = `[${FILTERS}] CMD_UI_ADD_FILTER_ELEM`
export const CMD_UI_REMOVE_FILTER_ELEM = `[${FILTERS}] CMD_UI_REMOVE_FILTER_ELEM`
export const EVT_FILTER_LIST_UPDATED = `[${FILTERS}] EVT_FILTER_LIST_UPDATED`
export const EVT_FILTER_ELEM_ADDED = `[${FILTERS}] EVT_FILTER_ELEM_ADDED`
export const EVT_FILTER_ELEM_REMOVED = `[${FILTERS}] EVT_FILTER_ELEM_REMOVED`

export const Fct_CMD_UI_ADD_FILTER_ELEM = (payload) => ({
  type: CMD_UI_ADD_FILTER_ELEM,
  payload
})

export const Fct_CMD_UI_REMOVE_FILTER_ELEM = (payload) => ({
  type: CMD_UI_REMOVE_FILTER_ELEM,
  payload
})

export const Fct_DOC_UPDATE_FILTER_LIST = (payload) => ({
  type: DOC_UPDATE_FILTER_LIST,
  payload
})

export const Fct_DOC_RESET_FILTERS = (payload) => ({
  type: DOC_RESET_FILTERS,
  payload
})

export const Fct_EVT_FILTER_LIST_UPDATED = (payload) => ({
  type: EVT_FILTER_LIST_UPDATED,
  payload
})

export const Fct_EVT_FILTER_ELEM_ADDED = (payload) => ({
  type: EVT_FILTER_ELEM_ADDED,
  payload
})

export const Fct_EVT_FILTER_ELEM_REMOVED = (payload) => ({
  type: EVT_FILTER_ELEM_REMOVED,
  payload
})
