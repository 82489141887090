// The reducers are very specific Actions / Commands
// The reducers only care about DOCUMENT actions or data persistance actions ie they will change the state
// The reducer takes an action or command as an input and will modify the state or store accordingly
// They will return a new state
// If he does nothing it will at least return the state or store unchanged
// Each reducer is responsible for updating a specific slice of the store

import { combineReducers } from "redux";

import entitiesReducer from "./entities.reducer";
import entityTypesReducer from "./entityTypes.reducer";
import filtersReducer from "./filters.reducer";
import notificationsReducer from "./notifications.reducer";
import uiReducer from "./ui.reducer";
import userJourneysReducer from "./userJourneys.reducer";

export const rootReducer = combineReducers({
  entities: entitiesReducer,
  entityTypes: entityTypesReducer,
  filters: filtersReducer,
  notifications: notificationsReducer,
  ui: uiReducer,
  userJourneys: userJourneysReducer,
});
