import * as APIActions from '../../01-Actions/api.actions'

import axios from 'axios'

export const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action) //Keeps the loogger well ordered

  var url = process.env.REACT_APP_API_FULL_URL

  switch (action.type) {
    case APIActions.CMD_CORE_GRAPHQL_REQUEST:
      const entityContextInfo = action.payload.meta.entityContextInfo
      const selectedYear = entityContextInfo.entitiesSelectedYear
      var today = new Date()
      const strTodayDateNum = today
        .toISOString()
        .substring(0, 10)
        .replace(/-/g, '')
      console.log(strTodayDateNum)
      let graphqlQuery = action.payload.meta.entityTypeInfo.graphQLQuery.replace(
        '<selectedYear>',
        selectedYear
      )
      graphqlQuery = graphqlQuery.replace('<todayDateNum>', strTodayDateNum)
      entityContextInfo.filterList.forEach(function (filterElem) {
        graphqlQuery = graphqlQuery.replace(
          '<' + filterElem.entityTypeInfo.primaryKeyFieldName + '>',
          filterElem.id
        )
      })

      axios({
        method: 'POST',
        url: url,
        headers: {
          'content-type': 'application/json'
        },
        data: {
          query: graphqlQuery,
          operationName: null,
          variables: null
        }
      })
        .then((res) => {
          dispatch(
            APIActions.Evt_ApiSuccess(
              res.data.data,
              action.payload.meta.entityTypeInfo,
              entityContextInfo
            )
          )
        })
        .catch((error) =>
          dispatch(
            APIActions.Evt_ApiError(
              error,
              action.payload.meta.entityTypeInfo,
              entityContextInfo
            )
          )
        )
      break
    case APIActions.CMD_CORE_API_REQUEST:
      axios
        .get(url, action.payload.meta.requestOptions)
        .then((res) => {
          dispatch(
            APIActions.Evt_ApiSuccess(
              res.data,
              action.payload.meta.entityTypeInfo,
              entityContextInfo
            )
          )
        })
        .catch((error) =>
          dispatch(
            APIActions.Evt_ApiError(
              error,
              action.payload.meta.entityTypeInfo,
              entityContextInfo
            )
          )
        )
      break
    default:
  }
}
