import EventNoteIcon from "@mui/icons-material/EventNote";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Months",
  displayName: "Months",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "calendarMonthId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Month",
  entityLabel1: "a month",
  additionalInfo: {
    itemIconSvg: <EventNoteIcon />,
  },
  itemCountPerSlice: 12,
  entityInitialState: {
    calendarMonthId: "2021-05",
    month: "May",
    year: "2021",
    monthText1: "May 2021",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: CalendarMonths  ( first:500, offset:0
        , orderBy:[calendarMonthId_desc]
      
      ) {
        entityId:calendarMonthId
        entityDescription:month
        entitySecondaryText:monthText1  
        filterKey       
      } 
  }`,
};
