import * as userJourneysActions from '../../01-Actions/userJourneys.actions'

export const userJourneysMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action) //Keeps the loogger well ordered
  if (action.type.indexOf('CMD_UI_') > -1) {
    //We detect a Command Coming from the UI
    dispatch(userJourneysActions.Fct_DOC_INCREMENT_UI_ACTION_COUNT())
  }
  if (action.type.indexOf('CMD_CORE_') > -1) {
    //We detect a Command Coming from the Core
    dispatch(userJourneysActions.Fct_DOC_INCREMENT_CORE_ACTION_COUNT())
  }
  if (action.type.indexOf('CMD_APP_') > -1) {
    //We detect a Command Coming from the App
    dispatch(userJourneysActions.Fct_DOC_INCREMENT_APP_ACTION_COUNT())
  }
}
