import * as entitiesActions from "../01-Actions/entities.actions";
import { dataObjects } from "../00-DataStore/dataObjects/";

const initialState = { dataObjects };

const entitiesReducer = (state = initialState, action) => {
  var newState = state;

  let myIndexStart = 0;
  let myIndexEnd = 100;

  switch (action.type) {
    case entitiesActions.DOC_CREATE_ENTITY:
      const newEntity = {
        id: newState.dataObjects.fullList.entitiesList.length + 1,
        ...action.payload.entity.entity,
      };
      newState.dataObjects.fullList.entitiesList.push(newEntity);
      newState.dataObjects.filteredList.entitiesList.push(newEntity);
      break;
    case entitiesActions.DOC_CLEAR_ENTITIES:
      newState.dataObjects.fullList.entitiesList = [];
      newState.dataObjects.filteredList.entitiesList = [];
      newState.dataObjects.displayedList.entitiesList = [];
      break;
    case entitiesActions.DOC_REMOVE_ENTITY:
      newState.dataObjects.fullList.entitiesList = state.dataObjects.fullList.entitiesList.filter(
        (entity) => entity.id !== action.payload.id
      );
      newState.dataObjects.filteredList.entitiesList = state.dataObjects.filteredList.entitiesList.filter(
        (entity) => entity.id !== action.payload.id
      );
      newState.dataObjects.displayedList.entitiesList = state.dataObjects.displayedList.entitiesList.filter(
        (entity) => entity.id !== action.payload.id
      );
      break;
    case entitiesActions.DOC_UPDATE_ENTITY:
      newState.dataObjects.fullList.entitiesList = state.dataObjects.fullList.entitiesList.map(
        (entity) =>
          entity.id !== action.payload.id
            ? entity
            : { ...entity, showBin: true }
      );
      break;
    case entitiesActions.DOC_UPDATE_ENTITIES_LIST:
      newState.dataObjects.fullList.entitiesList = action.payload;
      newState.entityCount = action.payload.length;
      newState.dataObjects.filteredList.entitiesList = [];
      newState.dataObjects.displayedList.entitiesList = [];
      break;
    case entitiesActions.DOC_REFRESH_SLICE_ENTITIES_LIST:
      myIndexStart = 0;
      myIndexEnd = 100;
      newState.dataObjects.displayedList.entitiesList = newState.dataObjects.filteredList.entitiesList.slice(
        myIndexStart,
        myIndexEnd
      );
      break;
    case entitiesActions.DOC_SLICE_ENTITIES_LIST:
      myIndexStart =
        (action.payload.mainListSliceIndex - 1) *
        action.payload.itemCountPerSlice;
      myIndexEnd = myIndexStart + action.payload.itemCountPerSlice;
      newState.dataObjects.displayedList.entitiesList = action.payload.filteredList.entitiesList.slice(
        myIndexStart,
        myIndexEnd
      );
      break;
    case entitiesActions.DOC_FILTER_ENTITIES_LIST:
      newState.dataObjects.filteredList = action.payload;
      newState.dataObjects.displayedList.entitiesList = [];
      break;
    default:
      break;
  }

  return newState;
};

export default entitiesReducer;
