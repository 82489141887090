import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Filtered Tournaments",
  displayName: "Tournaments",
  displayConditionFilter: ["Surfaces", "Categories", "Countries"],
  hideConditionFilter: ["Players", "Player Tournaments"],
  primaryKeyFieldName: "tournamentSeasonId",
  apiType: "graphQL",
  isVisible: false,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Tournament",
  entityLabel1: "a tournament",
  additionalInfo: {
    itemIconSvg: <ViewCarouselIcon />,
  },
  itemCountPerSlice: 50,
  entityInitialState: {
    tournamentSeasonId:
      "2021-atp-australian-open-melbourne-australia-men-singles",
    code: "AUSTR",
    name: "Australian Open Gran Slam (hard)",
    country: "AUSTRALIA",
    subCategory: "Gran Slam",
    surface: "hard",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: TournamentSeasons(     
      orderBy: [name_asc]      
      , filter: {
        OR: [        
          { filterKey_contains: "<surfaceId>"           },
          { filterKey_contains: "tournamentCategoryId"  },
          { filterKey_contains: "countryCode"           }          
        ]             
      }     
    ) {     
        entityId:tournamentSeasonId
        entityDescription:seasonName
        entitySecondaryText:location
        filterKey
    }
  }`,
};
