export const DOC_INCREMENT_UI_ACTION_COUNT =
  '[user Journey] DOC_INCREMENT_ACTION_COUNT'
export const DOC_INCREMENT_CORE_ACTION_COUNT =
  '[user Journey] DOC_INCREMENT_CORE_ACTION_COUNT'
export const DOC_INCREMENT_APP_ACTION_COUNT =
  '[user Journey] DOC_INCREMENT_APP_ACTION_COUNT'

export const Fct_DOC_INCREMENT_UI_ACTION_COUNT = () => ({
  type: DOC_INCREMENT_UI_ACTION_COUNT,
  payload: {}
})

export const Fct_DOC_INCREMENT_CORE_ACTION_COUNT = () => ({
  type: DOC_INCREMENT_CORE_ACTION_COUNT,
  payload: {}
})

export const Fct_DOC_INCREMENT_APP_ACTION_COUNT = () => ({
  type: DOC_INCREMENT_APP_ACTION_COUNT,
  payload: {}
})
