import pink from "@material-ui/core/colors/pink";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { myTheme } from "./myTheme.js";

const PaperEntitiesListHeight = 1000;
const PaperEntitiesListWidth = 600;
const PaperDefaultMarginRight = myTheme.spacing(2);

export const mystyles = {
  DivRoot: {
    flexGrow: 1,
    backgroundColor: "#424242",
    color: myTheme.palette.secondary.dark,
  },
  IconLose: {
    width: "1.2rem",
    height: "1.2rem",
    color: pink[600],
  },
  IconWin: {
    width: "1.2rem",
    height: "1.2rem",
    color: blueGrey[600],
  },
  PaperGrid: {
    padding: myTheme.spacing(2),
    textAlign: "center",
    color: myTheme.palette.text.secondary,
  },
  PaperApp: {
    backgroundColor: "#424242",
    height: 1000,
  },
  PaperEntityListt: {
    paddingTop: 3,
    paddingLeft: 0,
    paddingBottom: 3,
    marginRight: PaperDefaultMarginRight,
    marginTop: 0,
    marginBottom: 2,
    flexWrap: "wrap",
    width: PaperEntitiesListWidth,
    height: PaperEntitiesListHeight,
  },
};
