export const ENTITY_TYPES = "ENTITY_TYPES";
export const CMD_UI_SET_ENTITY_TYPE_SEL = `[${ENTITY_TYPES}] CMD_UI_SET_ENTITY_TYPE_SEL`;
export const DOC_SET_ENTITY_TYPE_SEL = `[${ENTITY_TYPES}] DOC_SET_ENTITY_TYPE_SEL`;
export const DOC_UPDATE_ENTITY_TYPE_LIST = `[${ENTITY_TYPES}] DOC_UPDATE_ENTITY_TYPE_LIST`;
export const EVT_ENTITY_TYPE_CHANGED = `[${ENTITY_TYPES}] EVT_ENTITY_TYPE_CHANGED`;

export const Fct_CMD_UI_SET_ENTITY_TYPE_SEL = (payload) => ({
  type: CMD_UI_SET_ENTITY_TYPE_SEL,
  payload,
});

export const Fct_DOC_SET_ENTITY_TYPE_SEL = (payload) => ({
  type: DOC_SET_ENTITY_TYPE_SEL,
  payload,
});

export const Fct_DOC_UPDATE_ENTITY_TYPE_LIST = (payload) => ({
  type: DOC_UPDATE_ENTITY_TYPE_LIST,
  payload,
});

export const Fct_EVT_ENTITY_TYPE_CHANGED = (payload) => ({
  type: EVT_ENTITY_TYPE_CHANGED,
  payload,
});
