import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Player Tournaments",
  displayName: "Player Tournaments",
  displayConditionFilter: ["Players"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "tournamentSeasonId",
  apiType: "graphQL",
  isVisible: false,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Tournament",
  entityLabel1: "a tournament",
  additionalInfo: {
    itemIconSvg: <ViewCarouselIcon />,
  },
  itemCountPerSlice: 50,
  entityInitialState: {
    tournamentSeasonId:
      "2021-atp-australian-open-melbourne-australia-men-singles",
    code: "AUSTR",
    name: "Australian Open Gran Slam (hard)",
    country: "AUSTRALIA",
    subCategory: "Gran Slam",
    surface: "hard",
  },
  graphQLQueryEntitiesAccessPath: ["Entities", "EntitiesLevel2"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: PlayerSeasons(
      first: 1
      offset: 0
      orderBy: [birthday_desc]
      filter: { playerSeasonId_not: "1900-01-01-unmatched-player" }
      playerSeasonId:  "<playerSeasonId>"
    ) {
      entityId: playerSeasonId
      entityDescription: name
      entitySecondaryText: shortDesc
      filterKey
      EntitiesLevel2: tournaments {
        entityId:tournamentSeasonId
        entityDescription:seasonName
        entitySecondaryText:location
        filterKey
      }
    }
  }`,
};
