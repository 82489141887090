import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Tournaments",
  displayName: "Tournaments",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["Players", "Countries", "Categories", "Surfaces"],
  primaryKeyFieldName: "tournamentSeasonId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Tournament",
  entityLabel1: "a tournament",
  additionalInfo: {
    itemIconSvg: <ViewCarouselIcon />,
  },
  itemCountPerSlice: 100,
  entityInitialState: {
    tournamentSeasonId:
      "2021-atp-australian-open-melbourne-australia-men-singles",
    code: "AUSTR",
    name: "Australian Open Gran Slam (hard)",
    country: "AUSTRALIA",
    subCategory: "Gran Slam",
    surface: "hard",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
    {
      Entities: TournamentSeasons  ( first:500, offset:0
          , orderBy:[firstMatchDate_desc]
          , filter: { tournamentSeasonId_not: "XXX" }
        ) {
          entityId:tournamentSeasonId
          entityDescription:seasonName
          entitySecondaryText:location
          filterKey
        }                  
    }`,
};
