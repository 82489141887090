import FlashOnIcon from '@mui/icons-material/FlashOn'

export const ENTITY_TYPE_DATA_INIT = {
  name: 'Events',
  displayName: 'Events',
  displayConditionFilter: ['<ALL>'],
  hideConditionFilter: ['DJs'],
  primaryKeyFieldName: 'eventId',
  apiType: 'graphQL',
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: 'https://api<env>.tennis-insider.com/',
  entityLabel: 'event',
  additionalInfo: {
    itemIconSvg: <FlashOnIcon />
  },
  entityLabel1: 'an event',
  itemCountPerSlice: 100,
  entityInitialState: {
    entityId: '70790-UltraMusicFestival2019',
    entityDescription: 'Ultra Music Festival 2019 - Day 1',
    entitySecondaryText: '2019-03-29'
  },
  graphQLQueryEntitiesAccessPath: ['Entities'],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities:Events  ( 
      first:500, offset:0
        , orderBy:[eventDate_asc]
        , seasonYear: "<selectedYear>"
        , , filter: { 
          AND: 
                [ 
                  {eventId_not: "XXX"},
                  { eventDateNum_gte : <todayDateNum>}  
                ] 
            }
        
      ) 
      
      {
        entityId:eventId
              entityDescription:eventName
              entitySecondaryText:shortDesc1
              filterKey
              extraInfoTab:djList (first:10, , filter: { countryCode_not: "XXX" }) {
                itemName: djName
              }
      }                  
  }`
}
