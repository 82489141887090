import * as ENTITY_TYPE_COUNTRIES from './countriesEntityType'
import * as ENTITY_TYPE_CATEGORIES from './categoriesEntityType'
import * as ENTITY_TYPE_LOSSES from './lossesEntityType'
import * as ENTITY_TYPE_CHALLENGER_VICTORIES from './victoriesAsChallengerEntityType'
import * as ENTITY_TYPE_FAVOURITE_LOSSES from './lossesAsFavouriteEntityType'
import * as ENTITY_TYPE_MATCHES from './matchesEntityType'
import * as ENTITY_TYPE_MONTHS from './monthsEntityType'
import * as ENTITY_TYPE_PLAYERS from './playersEntityType'
import * as ENTITY_TYPE_PLAYER_GROUPS from './playerGroupsEntityType'
import * as ENTITY_TYPE_SURFACES from './surfacesEntityType'
import * as ENTITY_TYPE_PLAYER_TOURNAMENTS from './playerTournamentsEntityType'
import * as ENTITY_TYPE_PLAYER_ODDS from './playerOddsEntityType'
import * as ENTITY_TYPE_PLAYER_ROUNDS from './playerRoundsEntityType'
import * as ENTITY_TYPE_TOURNAMENTS from './tournamentsEntityType'
import * as ENTITY_TYPE_TOURNAMENTS_FILTERED from './tournamentsFilteredEntityType'
import * as ENTITY_TYPE_MATCHES_FILTERED_BY_PLAYER from './matchesFilteredEntityType'
import * as ENTITY_TYPE_VICTORIES from './victoriesEntityType'

import * as ENTITY_TYPE_ODDS from './oddsEntityType'
import * as ENTITY_TYPE_ROUNDS from './roundsEntityType'
import * as ENTITY_TYPE_DJS from './djsEntityType'
import * as ENTITY_TYPE_VENUES from './venuesEntityType'
import * as ENTITY_TYPE_EVENTS from './eventsEntityType'
import * as ENTITY_TYPE_EVENTS_FILTERED_BY_PLAYER from './eventsFilteredEntityType'

const entityDomain = process.env.REACT_APP_ENTITY_DOMAIN

const entityTypesDomainTab = [
  {
    domainName: 'TENNIS',
    filterOnChar: '⭐',
    firstEntitiesDisplayCountInit: 4,
    domainList: [
      ENTITY_TYPE_MATCHES.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_MATCHES_FILTERED_BY_PLAYER.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_TOURNAMENTS_FILTERED.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_TOURNAMENTS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_PLAYER_TOURNAMENTS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_PLAYERS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_COUNTRIES.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_SURFACES.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_PLAYER_ODDS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_ODDS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_PLAYER_ROUNDS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_ROUNDS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_CATEGORIES.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_PLAYER_GROUPS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_MONTHS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_LOSSES.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_VICTORIES.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_FAVOURITE_LOSSES.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_CHALLENGER_VICTORIES.ENTITY_TYPE_DATA_INIT
    ]
  },
  {
    domainName: 'MOONSTRO',
    filterOnChar: '⭐',
    firstEntitiesDisplayCountInit: 4,
    domainList: [
      ENTITY_TYPE_EVENTS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_EVENTS_FILTERED_BY_PLAYER.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_DJS.ENTITY_TYPE_DATA_INIT,
      ENTITY_TYPE_VENUES.ENTITY_TYPE_DATA_INIT
    ]
  }
]

var entityTypesElemSearch = entityTypesDomainTab
  .filter((elem) => elem.domainName === entityDomain)
  .slice()

export const entitiesFilterOnChar =
  entityTypesElemSearch.length === 0
    ? entityTypesDomainTab[0].filterOnChar
    : entityTypesElemSearch[0].filterOnChar

export const entityTypes =
  entityTypesElemSearch.length === 0
    ? entityTypesDomainTab[0].domainList
    : entityTypesElemSearch[0].domainList

export const firstEntitiesDisplayCount =
  entityTypesElemSearch.length === 0
    ? entityTypesDomainTab[0].firstEntitiesDisplayCountInit
    : entityTypesElemSearch[0].firstEntitiesDisplayCountInit

const nbYears = 5
const currentYear = new Date().getFullYear() - nbYears
const yearsListManip = []

for (let i = 0; i < nbYears + 1; i++) {
  yearsListManip.push('' + (currentYear + i))
}

export const yearsList = yearsListManip
