export const DOC_SET_LOADER = "[ui] DOC_SET_LOADER";

export const CMD_GO_TO_MAIN_LIST_SLICE = "[ui] CMD_GO_TO_MAIN_LIST_SLICE";

export const CMD_GO_TO_HOME_PAGE = "[ui] CMD_GO_TO_HOME_PAGE";
export const EVT_USER_WENT_TO_SEARCH_PAGE = "[ui] EVT_USER_WENT_TO_SEARCH_PAGE";

export const CMD_GO_TO_SEARCH_PAGE = "[ui] CMD_GO_TO_SEARCH_PAGE";
export const EVT_USER_WENT_TO_HOME_PAGE = "[ui] EVT_USER_WENT_TO_HOME_PAGE";

export const CMD_CHANGE_SEARCH_TEXT = "[ui] CMD_CHANGE_SEARCH_TEXT";
export const EVT_SEARCHED_TEXT_CHANGED = "[ui] EVT_SEARCHED_TEXT_CHANGED";

export const CMD_SET_YEAR_SEL = `[ui] CMD_SET_YEAR_SEL`;
export const DOC_SET_YEAR_SEL = `[ui] DOC_SET_YEAR_SEL`;
export const EVT_YEAR_SEL_CHANGED = `[ui] EVT_YEAR_SEL_CHANGED`;

export const DOC_SET_MAIN_LIST_ENTITY_COUNT =
  "[ui] DOC_SET_MAIN_LIST_ENTITY_COUNT";
export const DOC_SET_MAIN_LIST_SLICE_COUNT =
  "[ui] DOC_SET_MAIN_LIST_SLICE_COUNT";
export const DOC_SET_MAIN_LIST_SLICE_INDEX =
  "[ui] DOC_SET_MAIN_LIST_SLICE_INDEX";
export const DOC_INCREMENT_MAIN_LIST_SLICE_INDEX =
  "[ui] DOC_INCREMENT_MAIN_LIST_SLICE_INDEX";
export const DOC_DECREMENT_MAIN_LIST_SLICE_INDEX =
  "[ui] DOC_DECREMENT_MAIN_LIST_SLICE_INDEX";
export const EVT_MAIN_LIST_SLICE_INDEX_CHANGED =
  "[ui] EVT_MAIN_LIST_SLICE_INDEX_CHANGED";

export const Fct_DOC_SET_LOADER = (state, entity) => ({
  type: DOC_SET_LOADER,
  payload: {
    data: state,
    meta: entity,
  },
});

export const Fct_CMD_GO_TO_MAIN_LIST_SLICE = (payload) => ({
  type: CMD_GO_TO_MAIN_LIST_SLICE,
  payload,
});

export const Fct_CMD_GO_TO_HOME_PAGE = (payload) => ({
  type: CMD_GO_TO_HOME_PAGE,
  payload,
});

export const Fct_CMD_GO_TO_SEARCH_PAGE = (payload) => ({
  type: CMD_GO_TO_SEARCH_PAGE,
  payload,
});

export const Fct_CMD_CHANGE_SEARCH_TEXT = (payload) => ({
  type: CMD_CHANGE_SEARCH_TEXT,
  payload,
});

export const Fct_CMD_SET_YEAR_SEL = (payload) => ({
  type: CMD_SET_YEAR_SEL,
  payload,
});

export const Fct_DOC_SET_YEAR_SEL = (payload) => ({
  type: DOC_SET_YEAR_SEL,
  payload,
});

export const Fct_DOC_SET_MAIN_LIST_SLICE_COUNT = (payload) => ({
  type: DOC_SET_MAIN_LIST_SLICE_COUNT,
  payload,
});

export const Fct_DOC_SET_MAIN_LIST_ENTITY_COUNT = (payload) => ({
  type: DOC_SET_MAIN_LIST_ENTITY_COUNT,
  payload,
});

export const Fct_DOC_SET_MAIN_LIST_SLICE_INDEX = (payload) => ({
  type: DOC_SET_MAIN_LIST_SLICE_INDEX,
  payload,
});

export const Fct_EVT_MAIN_LIST_SLICE_INDEX_CHANGED = (payload) => ({
  type: EVT_MAIN_LIST_SLICE_INDEX_CHANGED,
  payload,
});

export const Fct_EVT_USER_WENT_TO_SEARCH_PAGE = (payload) => ({
  type: EVT_USER_WENT_TO_SEARCH_PAGE,
  payload,
});

export const Fct_EVT_USER_WENT_TO_HOME_PAGE = (payload) => ({
  type: EVT_USER_WENT_TO_HOME_PAGE,
  payload,
});

export const Fct_EVT_SEARCHED_TEXT_CHANGED = (payload) => ({
  type: EVT_SEARCHED_TEXT_CHANGED,
  payload,
});

export const Fct_EVT_YEAR_SEL_CHANGED = (payload) => ({
  type: EVT_YEAR_SEL_CHANGED,
  payload,
});
