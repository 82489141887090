export const ENTITIES = 'entities'

export const CMD_APP_RESET_ENTITIES = `[${ENTITIES}] CMD_APP_RESET_ENTITIES`

export const CMD_GET_ENTITIES_FROM_CLOUD = `[${ENTITIES}] CMD_GET_ENTITIES_FROM_CLOUD`

export const CMD_UI_SET_ENTITY_SEL = `[${ENTITIES}] CMD_UI_SET_ENTITY_SEL`

export const CMD_UI_ADD_ENTITY = `[${ENTITIES}] CMD_UI_ADD_ENTITY`
export const DOC_CREATE_ENTITY = `[${ENTITIES}] DOC_CREATE_ENTITY`
export const EVT_ENTITY_CREATED = `[${ENTITIES}] EVT_ENTITY_CREATED`

export const CMD_UI_REMOVE_ENTITY = `[${ENTITIES}] CMD_UI_REMOVE_ENTITY`
export const DOC_REMOVE_ENTITY = `[${ENTITIES}] DOC_REMOVE_ENTITY`
export const EVT_ENTITY_REMOVED = `[${ENTITIES}] EVT_ENTITY_REMOVED`

export const DOC_UPDATE_ENTITY = `[${ENTITIES}] DOC_UPDATE_ENTITY`
export const DOC_REFRESH_SLICE_ENTITIES_LIST = `[${ENTITIES}] DOC_REFRESH_SLICE_ENTITIES_LIST`

export const DOC_UPDATE_ENTITIES_LIST = `[${ENTITIES}] DOC_UPDATE_ENTITIES_LIST`
export const EVT_ENTITIES_LIST_UPDATED = `[${ENTITIES}] EVT_ENTITIES_LIST_UPDATED`

export const DOC_SLICE_ENTITIES_LIST = `[${ENTITIES}] DOC_SLICE_ENTITIES_LIST`
export const EVT_ENTITIES_LIST_SLICED = `[${ENTITIES}] EVT_ENTITIES_LIST_SLICED`

export const DOC_FILTER_ENTITIES_LIST = `[${ENTITIES}] DOC_FILTER_ENTITIES_LIST`
export const EVT_ENTITIES_LIST_FILTERED = `[${ENTITIES}] EVT_ENTITIES_LIST_FILTERED`

export const CMD_UI_REMOVE_ALL_ENTITIES = `[${ENTITIES}] CMD_UI_REMOVE_ALL_ENTITIES`
export const DOC_CLEAR_ENTITIES = `[${ENTITIES}] DOC_CLEAR_ENTITIES`

export const Fct_CMD_APP_RESET_ENTITIES = (entityTypeInfo) => ({
  type: CMD_APP_RESET_ENTITIES,
  payload: {
    entityTypeInfo
  }
})

export const Fct_CMD_UI_ADD_ENTITY = (entity, entityTypeInfo) => ({
  type: CMD_UI_ADD_ENTITY,
  payload: {
    entity,
    entityTypeInfo
  }
})

export const Fct_CMD_GET_ENTITIES_FROM_CLOUD = (payload) => ({
  type: CMD_GET_ENTITIES_FROM_CLOUD,
  payload
})

export const Fct_CMD_UI_REMOVE_ENTITY = (id, entityTypeInfo) => ({
  type: CMD_UI_REMOVE_ENTITY,
  payload: {
    id: id,
    entityTypeInfo: entityTypeInfo
  }
})

export const Fct_DOC_UPDATE_ENTITY = (id, entityTypeInfo) => ({
  type: DOC_UPDATE_ENTITY,
  payload: {
    id: id,
    entityTypeInfo
  }
})

export const Fct_CMD_UI_REMOVE_ALL_ENTITIES = (entityTypeInfo) => ({
  type: CMD_UI_REMOVE_ALL_ENTITIES,
  payload: {
    entityTypeInfo
  }
})

export const Fct_EVT_ENTITY_REMOVED = (id, entityTypeInfo) => ({
  type: EVT_ENTITY_REMOVED,
  payload: {
    id: id,
    entityTypeInfo
  }
})

export const Fct_EVT_ENTITY_CREATED = (info, entityTypeInfo) => ({
  type: EVT_ENTITY_CREATED,
  payload: {
    message: info.entity.description,
    entityTypeInfo
  }
})

export const Fct_EVT_ENTITIES_LIST_FILTERED = (payload) => ({
  type: EVT_ENTITIES_LIST_UPDATED,
  payload
})

export const EVT_EntitiesListFiltered = (payload) => ({
  type: EVT_ENTITIES_LIST_FILTERED,
  payload
})

export const Fct_DOC_CREATE_ENTITY = (entity, entityTypeInfo) => ({
  type: DOC_CREATE_ENTITY,
  payload: {
    entity,
    entityTypeInfo
  }
})

export const Fct_DOC_REMOVE_ENTITY = (id, entityTypeInfo) => ({
  type: DOC_REMOVE_ENTITY,
  payload: {
    id: id,
    entityTypeInfo
  }
})

export const Fct_DOC_UPDATE_ENTITIES_LIST = (payload) => ({
  type: DOC_UPDATE_ENTITIES_LIST,
  payload
})

export const Fct_DOC_REFRESH_SLICE_ENTITIES_LIST = (
  entityTypeInfo,
  mainListSliceIndex,
  itemCountPerSlice
) => ({
  type: DOC_REFRESH_SLICE_ENTITIES_LIST,
  payload: { entityTypeInfo, mainListSliceIndex, itemCountPerSlice }
})

export const Fct_DOC_SLICE_ENTITIES_LIST = (payload) => ({
  type: DOC_SLICE_ENTITIES_LIST,
  payload
})

export const Fct_EVT_ENTITIES_LIST_SLICED = (payload) => ({
  type: EVT_ENTITIES_LIST_SLICED,
  payload
})

export const DOC_FilterEntitiesList = (payload) => ({
  type: DOC_FILTER_ENTITIES_LIST,
  payload
})

export const Fct_DOC_CLEAR_ENTITIES = (entityTypeInfo) => ({
  type: DOC_CLEAR_ENTITIES,
  payload: {
    entityTypeInfo
  }
})
