import { CREATE_NOTIFICATION } from "../01-Actions/notifications.actions";

const notificationsReducer = (notification = "", action) => {
  const { payload } = action;
  const entity =
    payload === undefined || payload.meta === undefined ? "" : payload.meta;
  switch (action.type) {
    case `${entity} ${CREATE_NOTIFICATION}`:
      return payload.data;
    default:
      return notification;
  }
};

export default notificationsReducer;
