import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

export const ENTITY_TYPE_DATA_INIT = {
  name: 'Venues',
  displayName: 'Venues',
  displayConditionFilter: ['<ALL>'],
  hideConditionFilter: ['<None>'],
  primaryKeyFieldName: 'venueId',
  apiType: 'graphQL',
  isVisible: false,
  showBin: false,
  filterIsOn: false,
  apiUrl: 'https://api<env>.tennis-insider.com/',
  entityLabel: 'venue',
  additionalInfo: {
    itemIconSvg: <AccountBalanceIcon />
  },
  entityLabel1: 'a venue',
  itemCountPerSlice: 100,
  entityInitialState: {
    entityId: '70790-Mansion-Club',
    entityDescription: 'Mansion Club',
    entitySecondaryText: 'Mansion Club in Miami'
  },
  graphQLQueryEntitiesAccessPath: ['Entities'],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities:Venues  ( first:500, offset:0
        , orderBy:[venueName_asc]
        , filter: { venueId_not: "XXX"  }
      ) {
        entityId:venueId
        entityDescription:venueName
        entitySecondaryText:countryCode
        filterKey
      }                  
  }`
}
