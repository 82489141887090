import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Players",
  displayName: "Players",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "playerSeasonId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Player",
  entityLabel1: "a player",
  itemCountPerSlice: 200,
  additionalInfo: {
    itemIconSvg: <PersonOutlineIcon />,
  },
  entityInitialState: {
    playerSeasonId: "2021-2001-08-16-Jannik-Sinner",
    name: "Jannik Sinner",
    birthday: "2001-08-16",
    country: "ITALY",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
      {
        Entities: PlayerSeasons  ( first:500, offset:0
            , orderBy:[birthday_desc]
            , filter: { playerSeasonId_not: "1900-01-01-unmatched-player"  }
          ) {
            entityId:playerSeasonId
            entityDescription:name
            entitySecondaryText:shortDesc
            filterKey              
          }                  
      }`,
};
