import DeviceHubIcon from "@mui/icons-material/DeviceHub";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Rounds",
  displayName: "Rounds",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["Players"],
  primaryKeyFieldName: "roundId",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Round",
  entityLabel1: "a round",
  additionalInfo: {
    itemIconSvg: <DeviceHubIcon />,
  },
  itemCountPerSlice: 12,
  entityInitialState: {
    entityId: "01-F",
    entityDescription: "Final",
    entitySecondaryText: "Final",
    filterKey: '{roundId:"01-F"}',
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: Rounds 
       ( 
          first:500, offset:0
        , orderBy:[roundId_asc]
        , filter: { AND: [
                            {roundId_not: "XXX" } , 
                            {roundId_not: "00-T"}
                        ]
                 }
        )   {
              entityId:roundId
              entityDescription:longName
              entitySecondaryText:name  
              filterKey       
            } 
  }`,
};
