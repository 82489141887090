import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./03-Reducers";
import { coreMiddleware, appMiddleware } from "./02a-Middleware";
import thunkMiddleware from "redux-thunk";
import monitorReducersEnhancer from "./04-Enhancers/monitorReducer";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware, ...coreMiddleware, ...appMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
