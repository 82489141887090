export const setNewFilteredEntitiesList = (payload) => {
  const { itemCountPerSlice, fullList, filterList } = payload;

  var filteredEntitiesList = fullList.slice();

  filterList.forEach(function (filterElem, index, array) {
    filteredEntitiesList = filteredEntitiesList.filter((entity) => {
      let filterKeyInit = entity.filterKey;
      let filterKey = entity.filterKey;

      let fieldName = "";
      let filterValue = filterElem.id;
      let filterValueJson = filterValue;

      if (filterElem.fieldName === "searchAll") {
        filterKey = entity.id;
      }
      if (filterElem.fieldName !== "searchAll") {
        fieldName = filterElem.entityTypeInfo[filterElem.fieldName];
        filterValue =
          filterKeyInit.indexOf(fieldName) > 0 ? filterElem.id : "<NoFilter>";
        filterValueJson = '<fieldName>:"<filterValue>"'
          .replace("<fieldName>", fieldName)
          .replace("<filterValue>", filterValue);
      }

      var check =
        filterValue === "<NoFilter>" ||
        filterKey.toUpperCase().indexOf(filterValueJson.toUpperCase()) > 0;

      return check;
    });
  });

  const entityCount = filteredEntitiesList.length;
  var sliceCount =
    entityCount === itemCountPerSlice
      ? 1
      : Math.floor(entityCount / itemCountPerSlice) + 1;
  if (
    entityCount % itemCountPerSlice === 0 &&
    Math.floor(entityCount / itemCountPerSlice) >= 1
  ) {
    sliceCount = sliceCount - 1;
  }

  return {
    entitiesList: filteredEntitiesList,
    sliceCount,
    entityCount,
  };
};
