import PublicIcon from "@mui/icons-material/Public";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Countries",
  displayName: "Countries",
  displayConditionFilter: ["<ALL>"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "countryCode",
  apiType: "graphQL",
  isVisible: true,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Country",
  entityLabel1: "a country",
  additionalInfo: {
    itemIconSvg: <PublicIcon />,
  },
  itemCountPerSlice: 100,
  entityInitialState: {
    countryCode: "FR",
  },
  graphQLQueryEntitiesAccessPath: ["Entities"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: Countries  ( first:500, offset:0
        , orderBy:[name_asc]
        , filter: { countryCode_not: "XXX" }
      ) {
        entityId:countryCode
        entityDescription:name
        entitySecondaryText:name  
        filterKey       
      } 
  }`,
};
