export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const Fct_CREATE_NOTIFICATION = (notification, entity) => ({
  type: `${entity} ${CREATE_NOTIFICATION}`,
  payload: {
    data: notification,
    meta: entity
  }
})
