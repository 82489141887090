import * as entityTypesActions from '../../01-Actions/entityTypes.actions'
import * as filtersActions from '../../01-Actions/filters.actions'
import { Fct_DOC_SET_LOADER } from '../../01-Actions/ui.actions'
import {
  entityTypes,
  entitiesFilterOnChar
} from '../../00-DataStore/entityTypes'

export const entityTypesMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action) //Keeps the loogger well ordered
  const { payload } = action

  switch (action.type) {
    case entityTypesActions.CMD_UI_SET_ENTITY_TYPE_SEL:
      dispatch(Fct_DOC_SET_LOADER(true, entityTypesActions.ENTITY_TYPES))
      dispatch(
        entityTypesActions.Fct_DOC_SET_ENTITY_TYPE_SEL({
          entityTypeSelName: payload.entityTypeSelName
        })
      )
      const newEntityTypeInfo = entityTypes.filter(
        (elem) =>
          elem.name ===
          payload.entityTypeSelName.replace(entitiesFilterOnChar, '')
      )[0]
      dispatch(
        entityTypesActions.Fct_EVT_ENTITY_TYPE_CHANGED({
          entityTypeInfo: newEntityTypeInfo,
          entityContextInfo: {
            filterList: payload.filterList,
            entitiesSelectedYear: payload.entitiesSelectedYear
          }
        })
      )
      setTimeout(() => {
        dispatch(Fct_DOC_SET_LOADER(false, entityTypesActions.ENTITY_TYPES))
      }, 500)
      break
    case filtersActions.EVT_FILTER_LIST_UPDATED:
      dispatch(
        entityTypesActions.Fct_DOC_UPDATE_ENTITY_TYPE_LIST({
          filterList: payload.filterList
        })
      )
      break
    default:
  }
}
