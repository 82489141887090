import DeviceHubIcon from "@mui/icons-material/DeviceHub";

export const ENTITY_TYPE_DATA_INIT = {
  name: "Player Odds",
  displayName: "Player Odds",
  displayConditionFilter: ["Players"],
  hideConditionFilter: ["<None>"],
  primaryKeyFieldName: "oddsRangeId",
  apiType: "graphQL",
  isVisible: false,
  showBin: false,
  filterIsOn: false,
  apiUrl: "https://api<env>.tennis-insider.com/",
  entityLabel: "Odd",
  entityLabel1: "a odd",
  additionalInfo: {
    itemIconSvg: <DeviceHubIcon />,
  },
  itemCountPerSlice: 50,
  entityInitialState: {
    RentityId: "Odds_0200_0210",
    entityDescription: "[2.0,2.1]",
    entitySecondaryText: "[2.0,2.1]",
    filterKey: '{oddsRangeId:"Odds_0200_0210"}',
  },
  graphQLQueryEntitiesAccessPath: ["Entities", "EntitiesLevel2"],
  graphQLQuery: `query GraphQLQueryRes
  {
    Entities: PlayerSeasons(
      first: 1
      offset: 0
      orderBy: [birthday_desc]
      filter: { playerSeasonId_not: "1900-01-01-unmatched-player" }
      playerSeasonId:  "<playerSeasonId>"
    ) {
      entityId: playerSeasonId
      entityDescription: name
      entitySecondaryText: shortDesc
      filterKey
      EntitiesLevel2:  odds {
        entityId:oddsRangeId
        entityDescription:name
        entitySecondaryText:name  
        filterKey       
      }
    }
  }`,
};
