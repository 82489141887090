import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import CancelIcon from '@mui/icons-material/Cancel'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { useDispatch, useSelector } from 'react-redux'
import * as entitiesActions from '../../actionManagement/01-Actions/entities.actions'
import * as filtersActions from '../../actionManagement/01-Actions/filters.actions'
import * as UIActions from '../../actionManagement/01-Actions/ui.actions'
import * as uiTools from '../tools/stringManipulation'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    color: '#fff'
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}))

export default function EntitiesList(props) {
  const classes = useStyles()
  const [dense] = useState(false)
  const [secondary] = useState(true)
  const state = useSelector((state) => state)
  const entityTypesList = state.entityTypes.entityTypesList
  const FILTER_LIST = state.filters.filterList
  const SELECTED_YEAR = state.ui.entitiesSelectedYear
  const ENTITY_TYPE = state.entityTypes.entityTypeSelName
  const ENTITY_TYPE_INFO = entityTypesList.filter(
    (elem) =>
      elem.name ===
      ENTITY_TYPE.replace(state.entityTypes.entitiesFilterOnChar, '')
  )[0]

  const {
    mainListSliceCount,
    mainListSliceIndex,
    mainListSliceIndexPrev,
    mainListSliceIndexNext
  } = state.ui

  const actionCount = state.userJourneys.actionCount

  const [entitiesListData, setEntitiesListData] = useState([])

  const dispatch = useDispatch()

  const cleanDisplayName = uiTools.cleanDisplayName

  const removeEntityClick = (id) => {
    dispatch(
      entitiesActions.Fct_CMD_UI_REMOVE_ENTITY(
        id,
        ENTITY_TYPE_INFO,
        'EntitiesList.IconButton.removeEntityClick'
      )
    )
    const entitiesListDataNew =
      state.entities.dataObjects.displayedList.entitiesList
    setEntitiesListData(entitiesListDataNew)
  }

  const addFilterElemClick = (id) => {
    const payload = {
      mainListSliceIndex,
      filterElem: {
        id,
        entityTypeInfo: ENTITY_TYPE_INFO,
        fieldName: 'primaryKeyFieldName'
      },
      filterList: FILTER_LIST,
      fullList: state.entities.dataObjects.fullList.entitiesList,
      eventTrigger: 'EntitiesList.IconButton.addFilterElemClick'
    }
    dispatch(filtersActions.Fct_CMD_UI_ADD_FILTER_ELEM(payload))
    const entitiesListDataNew =
      state.entities.dataObjects.displayedList.entitiesList
    setEntitiesListData(entitiesListDataNew)
  }

  const removeFilterElemClick = (id) => {
    const payload = {
      mainListSliceIndex,
      filterElem: {
        id,
        entityTypeInfo: ENTITY_TYPE_INFO,
        fieldName: 'primaryKeyFieldName'
      },
      filterList: FILTER_LIST,
      fullList: state.entities.dataObjects.fullList.entitiesList,
      eventTrigger: 'EntitiesList.IconButton.addFilterElemClick'
    }
    dispatch(filtersActions.Fct_CMD_UI_REMOVE_FILTER_ELEM(payload))
    const entitiesListDataNew =
      state.entities.dataObjects.displayedList.entitiesList
    setEntitiesListData(entitiesListDataNew)
  }

  const addEntityClick = () => {
    const description =
      'I added a new ' +
      ENTITY_TYPE +
      ' with Id#' +
      (1 + entitiesListData.length)
    const secondaryText = 'toto' + (1 + entitiesListData.length) + '@gmail.com'
    const showBin = true
    const entity = { description, secondaryText, showBin }
    dispatch(
      entitiesActions.Fct_CMD_UI_ADD_ENTITY(
        entity,
        ENTITY_TYPE_INFO,
        'EntitiesList.Button.handleClick.addEntityClick'
      )
    )
  }

  const loadEntitiesFromCloudPageLoad = useCallback(() => {
    dispatch(
      entitiesActions.Fct_CMD_GET_ENTITIES_FROM_CLOUD({
        entityTypeInfo: ENTITY_TYPE_INFO,
        entityContextInfo: {
          filterList: FILTER_LIST,
          entitiesSelectedYear: SELECTED_YEAR,
          uiTrigger: 'EntitiesList.Component.firstLoad'
        }
      })
    )
  }, [dispatch, ENTITY_TYPE_INFO, FILTER_LIST, SELECTED_YEAR])

  const loadEntitiesFromCloudClick = useCallback(() => {
    dispatch(
      entitiesActions.Fct_CMD_GET_ENTITIES_FROM_CLOUD({
        entityTypeInfo: ENTITY_TYPE_INFO,
        entityContextInfo: {
          filterList: FILTER_LIST,
          entitiesSelectedYear: SELECTED_YEAR,
          uiTrigger:
            'EntitiesList.Button.handleClick.loadEntitiesFromCloudClick'
        }
      })
    )
  }, [dispatch, ENTITY_TYPE_INFO, FILTER_LIST, SELECTED_YEAR])

  const clearEntitiesClick = () => {
    dispatch(
      entitiesActions.Fct_CMD_UI_REMOVE_ALL_ENTITIES(
        ENTITY_TYPE_INFO,
        'EntitiesList.Button.handleClick.clearEntitiesClick'
      )
    )
  }

  const nextSliceClick = () => {
    dispatch(
      UIActions.Fct_CMD_GO_TO_MAIN_LIST_SLICE({
        mainListSliceIndex: mainListSliceIndexNext,
        entityTypeInfo: ENTITY_TYPE_INFO,
        eventTrigger: 'EntitiesList.Button.handleClick.nextSliceClick',
        fullList: state.entities.dataObjects.fullList,
        filteredList: state.entities.dataObjects.filteredList
      })
    )
  }

  const prevSliceClick = () => {
    dispatch(
      UIActions.Fct_CMD_GO_TO_MAIN_LIST_SLICE({
        mainListSliceIndex: mainListSliceIndexPrev,
        entityTypeInfo: ENTITY_TYPE_INFO,
        eventTrigger: 'EntitiesList.Button.handleClick.prevSliceClick',
        fullList: state.entities.dataObjects.fullList,
        filteredList: state.entities.dataObjects.filteredList
      })
    )
  }

  const buttonActions = [
    { label: 'ADD', index: 0 },
    { label: 'RELOAD', index: 1 },
    { label: 'CLEAR', index: 2 }
  ]

  if (mainListSliceCount >= mainListSliceIndex && mainListSliceCount > 1) {
    buttonActions.push({ label: '<', index: 3 })
    buttonActions.push({
      label: `${mainListSliceIndex} / ${mainListSliceCount}`,
      index: 5
    })
  }

  if (mainListSliceCount > mainListSliceIndex) {
    buttonActions.push({ label: '>', index: 4 })
  }

  const handleClick = (index) => {
    switch (index) {
      case 0:
        addEntityClick()
        break
      case 1:
        loadEntitiesFromCloudClick()
        break
      case 2:
        clearEntitiesClick()
        break
      case 3:
        prevSliceClick()
        break
      case 4:
        nextSliceClick()
        break
      default:
    }
  }

  React.useEffect(() => {
    if (actionCount === 0) {
      loadEntitiesFromCloudPageLoad()
    }
  }, [loadEntitiesFromCloudPageLoad, actionCount])

  React.useEffect(() => {
    if (
      state.entities.dataObjects === undefined ||
      state.entities.dataObjects.displayedList.entitiesList.length === 0
    ) {
      setEntitiesListData([])
    } else {
      setEntitiesListData(state.entities.dataObjects.displayedList.entitiesList)
    }
  }, [state])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className={classes.demo}>
            <List dense={dense}>
              {entitiesListData.map((entity) => [
                <ListItem key={entity.id}>
                  <ListItemAvatar>
                    <Avatar>{entity.additionalInfo.itemIconSvg}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={cleanDisplayName(entity.description)}
                    secondary={
                      secondary ? cleanDisplayName(entity.secondaryText) : null
                    }
                  />
                  <ListItemSecondaryAction>
                    {entity.showBin ? (
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => removeEntityClick(entity.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}
                    <IconButton
                      edge='end'
                      aria-label='add filter'
                      onClick={() => addFilterElemClick(entity.id)}
                    >
                      <FilterListIcon />
                    </IconButton>
                    {ENTITY_TYPE_INFO.filterIsOn ? (
                      <IconButton
                        edge='end'
                        aria-label='remove filter'
                        onClick={() => removeFilterElemClick(entity.id)}
                      >
                        <CancelIcon />
                      </IconButton>
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>,
                <ListItem key={entity.id + 'x'}>
                  <Stack direction='row' spacing={1}>
                    {entity.extraInfoTab.map((chipElem) => (
                      <Chip
                        label={cleanDisplayName(chipElem.itemName)}
                        variant='outlined'
                      />
                    ))}
                  </Stack>
                </ListItem>
              ])}
            </List>
          </div>
          <ButtonGroup
            variant='text'
            color='primary'
            aria-label='text primary button group'
          >
            {buttonActions.map((elem) => [
              <Button onClick={() => handleClick(elem.index)} key={elem.index}>
                {cleanDisplayName(elem.label)}
              </Button>
            ])}
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  )
}
