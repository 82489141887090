import * as entityTypesActions from '../01-Actions/entityTypes.actions'
import {
  entityTypes,
  firstEntitiesDisplayCount,
  entitiesFilterOnChar,
  yearsList
} from '../00-DataStore/entityTypes/'

const initState = {
  entityTypeSelName: entityTypes[0].name,
  entityTypesList: entityTypes,
  yearsList,
  firstEntitiesDisplayCount,
  entityTypeLoaded: false,
  entitiesFilterOnChar
}

export const entityTypesReducer = (state = initState, action) => {
  const { payload } = action
  var newState = state

  switch (action.type) {
    case entityTypesActions.DOC_SET_ENTITY_TYPE_SEL:
      newState.entityTypeSelName = payload.entityTypeSelName
      break

    case entityTypesActions.DOC_UPDATE_ENTITY_TYPE_LIST:
      newState.entityTypesList = state.entityTypesList.map((entityType) => {
        var filterEntityType = '<NoFilter>'
        var newDisplayName = entityType.name
        var newIsVisible = entityType.isVisible

        newIsVisible =
          newIsVisible || entityType.displayConditionFilter[0] === '<ALL>'

        payload.filterList.forEach(function (filterElem, index, array) {
          filterEntityType = filterElem.entityTypeInfo.name

          newIsVisible =
            newIsVisible ||
            entityType.displayConditionFilter.filter(
              (elem) => elem === filterEntityType
            ).length > 0
        })

        payload.filterList.forEach(function (filterElem, index, array) {
          filterEntityType = filterElem.entityTypeInfo.name

          newIsVisible =
            newIsVisible &&
            entityType.hideConditionFilter.filter(
              (elem) => elem === filterEntityType
            ).length === 0

          newDisplayName =
            newDisplayName.indexOf(entitiesFilterOnChar) > 0 ||
            entityType.name === filterEntityType
              ? entityType.name + entitiesFilterOnChar
              : entityType.name
        })

        if (
          newIsVisible &&
          entityType.displayConditionFilter[0] !== '<ALL>' &&
          payload.filterList.length === 0
        ) {
          newIsVisible = false
        }

        return {
          ...entityType,
          filterIsOn: entityType.name === filterEntityType,
          isVisible: newIsVisible,
          displayName: newDisplayName
        }
      })
      break

    default:
      break
  }

  return newState
}

export default entityTypesReducer
