import { entitiesMiddleware } from "./entities.middleware";
import { entityTypesMiddleware } from "./entityTypes.middleware";
import { filtersMiddleware } from "./filters.middleware";
import { uiMiddleware } from "./ui.middleware";
import { userJourneysMiddleware } from "./userJourneys.middleware";
import thunk from "redux-thunk";

export const appMiddleware = [
  entitiesMiddleware,
  entityTypesMiddleware,
  filtersMiddleware,
  userJourneysMiddleware,
  uiMiddleware,
  thunk,
];
