import ReactGA from 'react-ga'
import { useEffect, Fragment } from 'react'
import './App.css'
import Paper from '@material-ui/core/Paper'
import { mystyles } from './mystyles-css'

import HomePage from './ui/pages/HomePage'
import configureStore from './actionManagement/configureStore'
import { Provider } from 'react-redux'

const store = configureStore()

if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
}

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  })

  return (
    <Provider store={store}>
      <Fragment>
        <Paper style={mystyles.PaperApp}>
          <HomePage />
        </Paper>
      </Fragment>
    </Provider>
  )
}

export default App
