import * as Actions from "../01-Actions/userJourneys.actions";

const initialState = {
  actionCount: 0,
  uiActionCount: 0,
  coreActionCount: 0,
  appActionCount: 0,
};

export const userJourneysReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.DOC_INCREMENT_UI_ACTION_COUNT:
      return {
        ...state,
        actionCount: state.actionCount + 1,
        uiActionCount: state.uiActionCount + 1,
      };
    case Actions.DOC_INCREMENT_CORE_ACTION_COUNT:
      return {
        ...state,
        actionCount: state.actionCount + 1,
        coreActionCount: state.coreActionCount + 1,
      };
    case Actions.DOC_INCREMENT_APP_ACTION_COUNT:
      return {
        ...state,
        actionCount: state.actionCount + 1,
        appActionCount: state.appActionCount + 1,
      };
    default:
      return state;
  }
};

export default userJourneysReducer;
